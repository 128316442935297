import * as React from "react";
import "../styles/faqs.scss";
import { useIntersection } from "../components/useIntersection";
import Image from "../components/Image";
import faqbg from "../images/faqs-bg.png";
import Collapsible from "react-collapsible";
export const Faqs = () => {
  let faqs = [
    {
      question: "Q.1 What is the mint Date?",
      answer: ` Final mint date & details will be announced soon on Twitter. Follow our Twitter
      for latest updates.`,
    },
    {
      question: "Q.2 How much will it cost?",
      answer: `NFT will cost around 0.25 SOL plus gas fees.`,
    },
    {
      question: "Q.3 What is Nekomimi Kat Club ?",
      answer: ` A NEKOMIMI is a proof of ownership of a digital (or other) asset. In this case the asset is exclusive ownership
      of the image that you receive when you mint your membership into the club.`,
    },
    {
      question: "Q.4 How Do I Buy one ?",
      answer: `You will need 2 things in order to buy (mint) a Nekomimi Kat Club NFT. Firstly
      you'll need a SOL compatible wallet, we recommend using Phantom.app, and the
      second is enough SOL to purchase the NFT and pay the Gas Fees.`,
    },
    {
      question: "Q.5 Why Nekomimi Kat Club?",
      answer: ` NEKOMIMI KAT CLUB is not just about the ownership of the NFT, but has so
      much more utility in the real world, through exclusive events and other member only
      activities. We're aiming to use the web3 world to improve our real world, through
      offline activations and offline charity activities to help those most in need.`,
    },
  ];
  return (
    <div className="faq-container" id="faqs">
      <img src={faqbg} className="faq-spot"></img>
      <div style={{ padding: "4rem 10%", position: "relative", zIndex: "2" }}>
        <div className="discord-container row">
          <div className="col-lg-6 col-md-6 col-sm-12 discord-content-container">
            <p className="discord-title">
              JOIN OUR COMMUNITY & GET EARLY ACCESS
            </p>
            <a href="https://discord.com/invite/HYbwZEgysj" target="_blank">
            <button className="discord-button">Join Discord</button>
            </a>
            
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-lg-end justify-content-md-center justify-content-sm-center">
            <div className="discord-img"></div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "8rem" }} id="faq">
          <div className="col-lg-6">
            <p className="subHeading">HELPFUL</p>
            <p className="heading">FAQs</p>
          </div>
          <div className="col-lg-6">
            {faqs.map((faq) => (
              <div className="question-container">
                <Collapsible trigger={faq.question}>
                  <p>{faq.answer}</p>
                </Collapsible>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faqs;
